import React from 'react';
import './Services.css';
const Services = () => {
    return (
        <section className="services">
            <div className="container">
                <h2>Our Services</h2>
                <div className="sections">
                    <div className="section">
                        <h3>CC routes Services</h3>
                        <p>SkritCreations is one of the reputed International wholesale carrier delivering a complete portfolio of Voice, SMS services since 2018. We are based in Singapore for Voice and SMS termination . With extensive experience and expertise in the Telecom industry we are dealing with A to Z wholesale , Retail and Dialer Voice terminations. We have highly qualified engineers to provide 24/7 support to our customers.</p>
                    </div>
                    <div className="section">
                        <h3>Wholesale Services</h3>
                        <p>We are connected with key Carriers across the globe which includes Tier1 carriers, many direct vendors which gives us ability to fix the problems on priority basis. We only provide quality and stable routes. We have dedicated Account Managers that work with you religiously to make sure that all of your needs are being met. We have strong internal IT systems that help us manage your account efficiently that keep you up to date on your rates, usage, and payment history round the clock </p>
                    </div>
                    <div className="section">
                        <h3>SMS Services</h3>
                        <p>SkritCreations offers messaging services to carriers and retail operators SMS Termination. During many years, the priorities of the company have been to provide our partners with high quality and reliable sms termination. Quality is key Point to any outbound or inbound call and agents love having a clear and stable line. Based on this we had creating different routing table as below.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;
